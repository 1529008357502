import React, {useState} from 'react';
import './App.css';
import {Box, Button, CircularProgress, TextField} from '@material-ui/core';

function App() {
    const [response, setResponse] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [buttonActiveStatus, setButtonActiveStatus] = useState(true)
    const [apiKey, setApiKey] = useState('')
    const [isWaitingResponse, setWaitingResponse] = useState(false)

    function onQueryValueChange(event: React.ChangeEvent<HTMLInputElement>) {
        let value = event.target.value;
        setPhoneNumber(value);
        if (value === '') {
            setResponse('')
        }
    }

    function onApiKeyValueChange(event: React.ChangeEvent<HTMLInputElement>) {
        let value = event.target.value;
        setApiKey(value);
        if (value === '') {
            setResponse('')
        }
    }

    function validate() {
        setButtonActiveStatus(false)
        setWaitingResponse(true)
        setResponse('')
        fetch(`https://api.compare-tech.com/phone-number-validation/v1/validate?phone_number=${phoneNumber}&is_cache_allowed=true`,
            {headers: {'x-api-key': apiKey}} as RequestInit)
            .then(r => r.json())
            .then(r => {
                setResponse(JSON.stringify(r, null, 2))
                setButtonActiveStatus(true)
                setWaitingResponse(false)
            })
            .catch(e => {
                console.error(`API -> validate() error: ${e}`)
                setResponse(JSON.stringify({"message:": `API -> validate() error: ${e}`}))
                setButtonActiveStatus(true)
                setWaitingResponse(false)
                return []
            })
    }

    return (
        <div className="App">
            <Box mt={4}>
                <TextField variant="outlined"
                           label="API Key"
                           placeholder="cV9WkVx79SAC49TfkcV9WkVx79SAC49Tfk"
                           value={apiKey}
                           onChange={onApiKeyValueChange}/>
            </Box>
            <Box mt={4}>
                <TextField variant="outlined"
                           label="Phone number"
                           placeholder="+39 111 222 3333"
                           value={phoneNumber}
                           onChange={onQueryValueChange}/>
            </Box>
            <Box m={4}>
                <Button disabled={!buttonActiveStatus} variant="contained" color="primary"
                        onClick={validate}>
                    Check
                </Button>
            </Box>
            {isWaitingResponse && <CircularProgress/>}
            <Box m={4}>
                <pre style={{textAlign: "left"}}>{response}</pre>
            </Box>
        </div>
    );
}

export default App;
